import { Rule } from "antd/es/form";
import dayjs, { Dayjs } from "dayjs";

export const minDateValidator =
  (minDate: Dayjs, message: string, inactive?: boolean) =>
  (_: Rule, value: string) => {
    if (inactive || !value || dayjs(value).isAfter(minDate)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(message));
  };

export const maxDateValidator =
  (minDate: Dayjs, message: string, inactive?: boolean) =>
  (_: Rule, value: string) => {
    if (inactive || !value || dayjs(value).isBefore(minDate)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(message));
  };
