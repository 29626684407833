import React from "react";

import ChevronRight from "resources/img/icons/chevron-right.svg";
import InfoIcon from "resources/img/icons/info--grey.svg";
import GreenTick from "resources/img/icons/tick--green.svg";

import amlIcon from "resources/img/products/aml_check.svg";
import authenticationIcon from "resources/img/products/authentication.svg";
import businessVerificationIcon from "resources/img/products/business_verification.svg";
import kycServicesIcon from "resources/img/products/kyc-services.svg";
import locationIcon from "resources/img/products/smartcheck_address_icon.svg";
import registrationIcon from "resources/img/products/user-registration.svg";

function ServiceIcon({ id }) {
  const iconMap = {
    aml: amlIcon,
    kyc_services: kycServicesIcon,
    kyb_services: businessVerificationIcon,
    user_registration: registrationIcon,
    authentication: authenticationIcon,
    address_verification: locationIcon,
  };

  const serviceIcon = iconMap[id] || InfoIcon;
  return (
    <img
      className="web-app__header-icon icon--large"
      src={serviceIcon}
      alt={id}
    />
  );
}

function Service({ service, serviceCollection, actionClicked, action }) {
  const [actionID, setActionId] = React.useState(action?.action_id);

  const onCheck = (action) => {
    setActionId(action.action_id);
    actionClicked(action);
  };

  return (
    <div>
      <div className="web-app__wizard-service">
        <div className="web-app__wizard-service-header-container">
          <ServiceIcon id={service.service_id} />
          <div className="web-app__wizard-service-header">
            <div className="web-app__wizard-service-name">{service.name}</div>
            <div className="web-app__wizard-service-subtext">
              {service.tag_line}
            </div>
          </div>
        </div>

        <div className="web-app__wizard-service-actions">
          {service.actions.map((action) => (
            <div key={action.action_id}>
              {serviceCollection ? (
                <div className="web-app__wizard-service-action">
                  <img src={GreenTick} alt="tick" />
                  <div className="web-app__wizard-service-action-name">
                    {action.name}
                    <span
                      className="web-app__tooltip"
                      data-tooltip={action.description}
                    >
                      <img src={InfoIcon} alt="info" />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="web-app__wizard-service-action web-app__wizard-service-action--selector">
                  <input
                    className="web-app__wizard-service-action-radio"
                    type="radio"
                    name="action"
                    value={action.name}
                    checked={action.action_id === actionID}
                    onChange={() => onCheck(action)}
                  />
                  <div
                    data-testid={action.action_id}
                    className="web-app__wizard-service-action-name"
                    onClick={() => onCheck(action)}
                  >
                    {action.name}
                    <span
                      className="web-app__tooltip"
                      data-tooltip={action.description}
                    >
                      <img src={InfoIcon} alt="info" />
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {serviceCollection && (
          <div className="web-app__wizard-service-chevron">
            <img className="icon--small" src={ChevronRight} alt="Next" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Service;
