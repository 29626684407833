import React from "react";
import {
  RetweetOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SunOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import {
  IconBrightnessFilled,
  IconFlipHorizontal,
  IconFlipVertical,
} from "@tabler/icons-react";
import { useKeyPress } from "ahooks";
import { Button, Space, Tooltip } from "antd";

interface ImageManipulationToolsProps {
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onReset?: () => void;
  onRotateLeft?: () => void;
  onRotateRight?: () => void;
  onFlipHorizontal?: () => void;
  onFlipVertical?: () => void;
  onToggleNegativeFilter: () => void;
  onToggleBrightnessFilter: () => void;
  extra?: React.ReactNode;
  stopAnimating?: () => void;
  isHotKeysActive?: boolean;
  onTranslateUp?: () => void;
  onTranslateDown?: () => void;
  onTranslateLeft?: () => void;
  onTranslateRight?: () => void;
}

const ImageManipulationTools: React.FC<ImageManipulationToolsProps> = ({
  onZoomIn,
  onZoomOut,
  onReset,
  onRotateLeft,
  onRotateRight,
  onFlipHorizontal,
  onFlipVertical,
  onToggleNegativeFilter,
  onToggleBrightnessFilter,
  extra,
  stopAnimating,
  isHotKeysActive,
  onTranslateUp,
  onTranslateDown,
  onTranslateLeft,
  onTranslateRight,
}) => {
  const handleHotKey = (action?: () => void) => () => {
    if (action && isHotKeysActive) {
      action();
      stopAnimating?.();
    }
  };

  const handleClick = (action?: () => void) => () => {
    if (action) {
      action();
      stopAnimating?.();
    }
  };

  useKeyPress("c", handleHotKey(onReset), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("r", handleHotKey(onRotateRight), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("l", handleHotKey(onRotateLeft), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("z", handleHotKey(onZoomIn), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("x", handleHotKey(onZoomOut), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("h", handleHotKey(onFlipHorizontal), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("v", handleHotKey(onFlipVertical), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("w", handleHotKey(onTranslateUp), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("a", handleHotKey(onTranslateLeft), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("s", handleHotKey(onTranslateDown), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("d", handleHotKey(onTranslateRight), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("i", handleHotKey(onToggleNegativeFilter), {
    exactMatch: true,
    useCapture: true,
  });
  useKeyPress("o", handleHotKey(onToggleBrightnessFilter), {
    exactMatch: true,
    useCapture: true,
  });

  return (
    <div className="flex justify-center">
      <Space className="my-4 px-2">
        {onZoomIn && (
          <Tooltip title="Zoom In (Z)">
            <Button
              onClick={handleClick(onZoomIn)}
              icon={<ZoomInOutlined role="presentation" />}
              aria-label="Zoom In"
            />
          </Tooltip>
        )}
        {onZoomOut && (
          <Tooltip title="Zoom Out (X)">
            <Button
              onClick={handleClick(onZoomOut)}
              icon={<ZoomOutOutlined role="presentation" />}
              aria-label="Zoom Out"
            />
          </Tooltip>
        )}
        {onReset && (
          <Tooltip title="Reset (C)">
            <Button
              onClick={handleClick(onReset)}
              icon={<RetweetOutlined role="presentation" />}
              aria-label="Reset"
            />
          </Tooltip>
        )}
        {onRotateLeft && (
          <Tooltip title="Rotate Left (L)">
            <Button
              onClick={handleClick(onRotateLeft)}
              icon={<RotateLeftOutlined role="presentation" />}
              aria-label="Rotate Left"
            />
          </Tooltip>
        )}
        {onRotateRight && (
          <Tooltip title="Rotate Right (R)">
            <Button
              onClick={handleClick(onRotateRight)}
              icon={<RotateRightOutlined role="presentation" />}
              aria-label="Rotate Right"
            />
          </Tooltip>
        )}
        {onFlipHorizontal && (
          <Tooltip title="Flip Horizontally (H)">
            <Button
              onClick={handleClick(onFlipHorizontal)}
              icon={
                <IconFlipVertical
                  className="align-middle"
                  role="presentation"
                  strokeWidth={1.5}
                />
              }
              aria-label="Flip Horizontally"
            />
          </Tooltip>
        )}
        {onFlipVertical && (
          <Tooltip title="Flip Vertically (V)">
            <Button
              onClick={handleClick(onFlipVertical)}
              icon={
                <IconFlipHorizontal
                  className="align-middle"
                  role="presentation"
                  strokeWidth={1.5}
                />
              }
              aria-label="Flip Vertically"
            />
          </Tooltip>
        )}
        <Tooltip title="Invert Colors (I)">
          <Button
            onClick={handleClick(onToggleNegativeFilter)}
            icon={
              <IconBrightnessFilled
                className="align-middle"
                role="presentation"
                strokeWidth={1.5}
              />
            }
            aria-label="Invert Colors"
          />
        </Tooltip>
        <Tooltip title="Change Brightness (O)">
          <Button
            onClick={handleClick(onToggleBrightnessFilter)}
            icon={<SunOutlined role="presentation" />}
            aria-label="Change Brightness"
          />
        </Tooltip>
        {extra}
      </Space>
    </div>
  );
};

export default ImageManipulationTools;
