import React from "react";
import {
  Alert,
  App,
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  theme,
  Typography,
} from "antd";
import dayjs, { isDayjs } from "dayjs";

import { ReviewCreateResponse, useReviewCreate } from "mutations";
import antdTheme from "theme";
import { Review } from "types/api/review";
import { ISOCountryCodeToFullNameMap } from "util/format_helpers";
import { maxDateValidator, minDateValidator } from "util/validators";

interface FormValues {
  address: string;
  full_name: string;
  dob: Date;
  id_number: string;
  expiration_date: Date;
  issuance_date: Date;
  secondary_id_number: string;
  country: string;
  id_type: string;
  phone_number: string;
  phone_number2: string;
  gender: "M" | "F";
  outcome: "Approved" | "Rejected";
  rejection_reason: string | null;
}

interface DocumentReviewFormProps {
  review: Review;
  completed: boolean;
  callback: (data: ReviewCreateResponse) => void;
  next: () => void;
}

const DocumentReviewForm: React.FC<DocumentReviewFormProps> = ({
  review,
  completed,
  callback,
  next,
}) => {
  const { notification } = App.useApp();
  const reviewCreate = useReviewCreate();
  const [form] = Form.useForm<FormValues>();
  const outcome = Form.useWatch("outcome", form);
  const rejectionReason = Form.useWatch("rejection_reason", form);

  const comparisons = completed
    ? review.smile_reference?.internal.Payload.Comparisons || []
    : review.links.textual_comparisons || [];
  const comparisonsObj = Object.fromEntries(
    comparisons.map(({ key, value }) => [key, value]),
  );

  const country =
    ISOCountryCodeToFullNameMap[comparisonsObj.country] ||
    comparisonsObj.country;
  const idType = comparisonsObj.id_type?.split("_")?.join(" ");

  const handleSubmit = ({
    rejection_reason,
    outcome,
    ...values
  }: FormValues) => {
    reviewCreate.mutate(
      {
        review: {
          textual_comparisons: Object.entries(values).map(([key, value]) => {
            let newValue;
            if (typeof value === "string") {
              newValue =
                value === "Not Available" ? value : value.toUpperCase();
            }
            if (isDayjs(value)) {
              newValue = dayjs(value).format("YYYY-MM-DD");
            }
            return {
              key,
              value: newValue || "Not Available",
            };
          }),
          rejection_reason: rejection_reason || null,
        },
        id: review.id,
      },
      {
        onSuccess: (data) => {
          callback?.(data);
          next();
        },
        onError: (error) => {
          notification.error({
            message: error.response?.data.message || "Failed to submit review",
          });
        },
      },
    );
  };

  const completedOutcome = review.smile_reference?.internal.Payload
    .RejectionReason
    ? "Rejected"
    : "Approved";

  return (
    <ConfigProvider
      theme={{
        algorithm: completed ? undefined : theme.darkAlgorithm,
        ...antdTheme,
      }}
    >
      <Typography.Title level={2} className="h2">
        {country} {idType}
      </Typography.Title>
      {review.creation_reasons
        ?.filter((reason) => reason !== "Randomly Selected")
        .map((reason) => (
          <Alert className="mb-2" key={reason} message={reason} type="error" />
        ))}
      <Form
        disabled={completed}
        form={form}
        initialValues={{
          address: comparisonsObj.address,
          id_type: comparisonsObj.id_type,
          country: comparisonsObj.country,
          phone_number: comparisonsObj.phone_number,
          phone_number2: comparisonsObj.phone_number2,
          outcome: completed ? completedOutcome : undefined,
          rejection_reason: completed
            ? review.smile_reference?.internal.Payload.RejectionReason
            : undefined,
          gender: completed
            ? comparisonsObj.gender || "Not Available"
            : comparisonsObj.gender,
          full_name: comparisonsObj.full_name,
          dob:
            comparisonsObj.dob && dayjs(comparisonsObj.dob).isValid()
              ? dayjs(comparisonsObj.dob)
              : undefined,
          id_number: comparisonsObj.id_number,
          expiration_date:
            comparisonsObj.expiration_date &&
            dayjs(comparisonsObj.expiration_date).isValid()
              ? dayjs(comparisonsObj.expiration_date)
              : undefined,
          issuance_date:
            comparisonsObj.issuance_date &&
            dayjs(comparisonsObj.issuance_date).isValid()
              ? dayjs(comparisonsObj.issuance_date)
              : undefined,
          secondary_id_number: comparisonsObj.secondary_id_number,
        }}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="full_name"
          label="Full name"
          dependencies={["outcome", "rejection_reason"]}
          rules={[
            {
              required:
                outcome === "Approved" ||
                rejectionReason === "Expired Document",
              message: "Full name is required",
              whitespace: true,
            },
          ]}
        >
          <Input className="w-full font-mono" size="large" />
        </Form.Item>
        <Form.Item
          name="dob"
          label="Date of birth (YYYY-MM-DD)"
          rules={[
            {
              type: "date",
              warningOnly: true,
              validator: maxDateValidator(
                dayjs(),
                "Date of birth should be in the past",
              ),
            },
          ]}
        >
          <DatePicker
            className="w-full font-mono"
            format={{
              format: "YYYY-MM-DD",
              type: "mask",
            }}
            placeholder={completed ? "Not Available" : undefined}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="id_number"
          label="ID number"
          dependencies={["outcome", "rejection_reason"]}
          rules={[
            {
              required:
                outcome === "Approved" ||
                rejectionReason === "Expired Document",
              message: "ID number is required",
              whitespace: true,
            },
          ]}
        >
          <Input className="w-full font-mono" size="large" />
        </Form.Item>
        <Form.Item
          name="expiration_date"
          label="Expiration date (YYYY-MM-DD)"
          dependencies={["outcome", "rejection_reason"]}
          rules={[
            {
              required: rejectionReason === "Expired Document",
              message: "Expiration date is required",
              type: "date",
            },
            {
              type: "date",
              warningOnly: true,
              validator: minDateValidator(
                dayjs(),
                "Expiration date should be in the future",
                outcome !== "Approved",
              ),
            },
          ]}
        >
          <DatePicker
            className="w-full font-mono"
            format={{
              format: "YYYY-MM-DD",
              type: "mask",
            }}
            placeholder={completed ? "Not Available" : undefined}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="issuance_date"
          label="Issuance date (YYYY-MM-DD)"
          rules={[
            {
              type: "date",
              warningOnly: true,
              validator: maxDateValidator(
                dayjs(),
                "Issuance date should be in the past",
              ),
            },
          ]}
        >
          <DatePicker
            className="w-full font-mono"
            format={{
              format: "YYYY-MM-DD",
              type: "mask",
            }}
            placeholder={completed ? "Not Available" : undefined}
            size="large"
          />
        </Form.Item>
        <Form.Item name="secondary_id_number" label="Secondary ID number">
          <Input className="w-full font-mono" size="large" />
        </Form.Item>
        <Form.Item hidden name="address" label="Address">
          <Input disabled className="w-full font-mono" size="large" />
        </Form.Item>
        <Form.Item hidden name="country" label="Country">
          <Input disabled className="w-full font-mono" size="large" />
        </Form.Item>
        <Form.Item hidden name="id_type" label="ID type">
          <Input disabled className="w-full font-mono" size="large" />
        </Form.Item>
        <Form.Item hidden name="phone_number" label="Phone number">
          <Input disabled className="w-full font-mono" size="large" />
        </Form.Item>
        <Form.Item hidden name="phone_number2" label="Phone number">
          <Input disabled className="w-full font-mono" size="large" />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: "Gender is required" }]}
        >
          <Radio.Group>
            <Radio value="M">Male</Radio>
            <Radio value="F">Female</Radio>
            <Radio value="Not Available">Not available</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="outcome"
          label="Outcome"
          rules={[{ required: true, message: "Outcome is required" }]}
        >
          <Radio.Group>
            <Radio value="Approved">Approved</Radio>
            <Radio value="Rejected">Other outcomes</Radio>
          </Radio.Group>
        </Form.Item>
        {outcome === "Rejected" && (
          <Form.Item
            name="rejection_reason"
            label="Other outcome"
            rules={[{ required: true, message: "Other outcome is required" }]}
          >
            {completed ? (
              <Input className="w-full font-mono" size="large" readOnly />
            ) : (
              <Select
                className="w-full font-mono"
                showSearch
                options={[
                  { label: "Cropped Document", value: "Cropped Document" },
                  { label: "Expired Document", value: "Expired Document" },
                  { label: "Not A Document", value: "Not A Document" },
                  {
                    label: "Potential Fraud Attempt",
                    value: "Potential Fraud Attempt",
                  },
                  {
                    label: "Photocopied Document",
                    value: "Photocopied Document",
                  },
                  {
                    label: "Unreadable Document",
                    value: "Unreadable Document",
                  },
                  {
                    label: "Unsupported Document",
                    value: "Unsupported Document",
                  },
                  {
                    label: "Wrong Document Type",
                    value: "Wrong Document Type",
                  },
                ]}
                size="large"
              />
            )}
          </Form.Item>
        )}
        {!completed && (
          <Form.Item>
            <Button
              className="shadow-none"
              htmlType="submit"
              loading={reviewCreate.isPending}
              size="large"
              type="primary"
            >
              Submit Review
            </Button>
          </Form.Item>
        )}
      </Form>
    </ConfigProvider>
  );
};

export default DocumentReviewForm;
