import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";
import { useFlags } from "launchdarkly-react-client-sdk";
import { connect } from "react-redux";

import Select from "react-select";

import AddressVerificationFields from "components/partner/web_app/AddressVerificationFields";
import {
  DisplayEnvironment,
  getEnvironment,
} from "contexts/displayEnvironment";
import InfoIcon from "resources/img/icons/info--grey.svg";
import InfoIconRed from "resources/img/icons/info--red.svg";
import { postWebApp } from "util/api_util";
import { JobTypes } from "util/constants";
import {
  ISOCountryCodeToFullNameMap,
  generateRandomID,
  getCountry,
} from "util/format_helpers";
import { getProviderImage } from "util/web_app_helpers";
import OutOfCreditsModal from "./out_of_credits_modal";

// The Dropdown expects an array of objects with label and value
const countries = Object.keys(ISOCountryCodeToFullNameMap).map(
  (countryCode) => ({
    label: ISOCountryCodeToFullNameMap[countryCode],
    value: countryCode,
  }),
);

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: jwtDecode(localStorage.token),
      user_id: undefined,
      country: undefined,
      id_type: undefined,
      id_number: undefined,
      utility_provider: undefined,
      address: undefined,
      full_name: undefined,

      first_name: undefined,
      middle_name: "",
      last_name: undefined,
      email: undefined,
      phone_number: undefined,

      bank_code: undefined,
      citizenship: undefined,
      company: undefined,
      countries: [],
      strict_match: true,

      date: undefined,
      month: undefined,
      year: undefined,

      images: [],
      selfie_images: undefined,
      id_images: undefined,

      disabled: false,
      jobRunning: false,
      error: null,
      service_price: 0,
      jobType: parseInt(props.action?.job_type, 10),
      showOutOfCreditsModal: false,
    };
    this.generateUserId = this.generateUserId.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCountriesChange = this.handleCountriesChange.bind(this);
    this.handleDocvCountryChange = this.handleDocvCountryChange.bind(this);
    this.handleIDTypeChange = this.handleIDTypeChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.redirectToResult = this.redirectToResult.bind(this);
    this.onSubmitDetails = this.onSubmitDetails.bind(this);
    this.onBackButton = this.onBackButton.bind(this);
    this.toggleOutOfCreditsModal = this.toggleOutOfCreditsModal.bind(this);
  }

  handleChange(event) {
    const key = event.target.name;
    this.setState({ [key]: event.target.value.trim() });
  }

  handleCountryChange(event) {
    this.handleChange(event);
    this.setState({ id_type: "", service_price: 0 });
  }

  handleCountriesChange(selectedCountries) {
    const countries = selectedCountries.map((country) => country.value);
    this.setState({ countries });
  }

  handleDocvCountryChange(country) {
    this.setState({ country: country.value });
  }

  toggleOutOfCreditsModal() {
    this.setState((prevState) => ({
      showOutOfCreditsModal: !prevState.showOutOfCreditsModal,
    }));
  }

  handleIDTypeChange(event) {
    this.handleChange(event);
    const { country } = this.state;
    const id_type = event.target.value;
    if (!country || !id_type) {
      return;
    }
    if (this.state.jobType !== JobTypes.BUSINESS_VERIFICATION) {
      return;
    }
    const business_verification_product = this.props.products.find(
      (val) => val.computer_name === "business_verification",
    );
    if (!business_verification_product) {
      return;
    }
    const base_cost = parseFloat(
      business_verification_product.components[
        JobTypes.BUSINESS_VERIFICATION.toString()
      ].price,
    );
    const id_types =
      business_verification_product.business_verification[getCountry(country)];
    if (id_types && id_types[id_type]) {
      const mark_up = parseFloat(id_types[id_type].data_return.price);
      this.setState({ service_price: base_cost + mark_up });
    }
  }

  handleImageChange(e) {
    const { images } = this.state;
    const { id } = e.target;
    images.forEach((image, index) => {
      if (`${image.image_type_id}` === id) {
        images.splice(index, 1);
      }
    });

    Object.keys(e.target.files).forEach((key) => {
      const reader = new FileReader();
      const file = e.target.files[key];

      reader.onloadend = () => {
        images.push({
          image_type_id: parseInt(id, 10),
          image: reader.result.split(",")[1],
        });
      };

      reader.readAsDataURL(file);
    });

    this.setState({
      images,
    });
  }

  onBackButton() {
    this.props.onBackButton();
  }

  setDob() {
    if (this.state.date) {
      if (this.state.month.length === 1) {
        const month = `0${this.state.month}`;
        return `${this.state.year}-${month}-${this.state.date}`;
      }
      return `${this.state.year}-${this.state.month}-${this.state.date}`;
    }
  }

  createRequestPayload() {
    return {
      id_info: {
        address: this.state.address,
        id_type: this.state.id_type,
        id_number: this.state.id_number,
        country: this.state.country,
        countries: this.state.countries,
        full_name: this.state.full_name,
        birth_year: this.state.birth_year,
        first_name: this.state.first_name,
        middle_name: this.state.middle_name,
        last_name: this.state.last_name,
        bank_code: this.state.bank_code,
        citizenship: this.state.citizenship,
        company: this.state.company,
        business_type: this.state.business_type,
        postal_code: this.state.postal_code,
        postal_address: this.state.postal_address,
        strict_match: this.state.strict_match,
        dob: this.setDob(),
        entered: `${this.evaluateEntered()}`,
        utility_provider: this.state.utility_provider,
      },
      partner_params: {
        user_id: this.state.user_id || generateRandomID(24),
        job_id: generateRandomID(24),
        job_type: this.state.jobType,
      },
      images: this.state.images,
    };
  }

  evaluateEntered() {
    return [1, 5].includes(this.state.jobType) &&
      this.state.id_type &&
      this.state.id_number &&
      this.state.country
      ? "true"
      : "false";
  }

  validateRequest(request) {
    const requiredFields = this.getRequiredFields();
    const keys = Object.keys(request);
    let passed = true;
    for (let i = 0; i < keys.length; i += 1) {
      if (Array.isArray(request[keys[i]])) {
        continue;
      } else if (typeof request[keys[i]] === "object") {
        passed = this.validateRequest(request[keys[i]]);
        if (!passed) {
          break;
        }
      } else if (requiredFields.includes(keys[i])) {
        if (!request[keys[i]] || request[keys[i]].length === 0) {
          this.setState({
            disabled: false,
            jobRunning: false,
            error: `${keys[i]} is required for this ID type`,
          });
          passed = false;
          break;
        }
      }
    }
    if ([1, 2, 4, 6].includes(this.state.jobType)) {
      if (!this.state.images.some((image) => image.image_type_id === 2)) {
        this.setState({
          disabled: false,
          jobRunning: false,
          error: "A selfie image is required",
        });
        passed = false;
      }
    }
    if ([6].includes(this.state.jobType)) {
      if (!this.state.images.some((image) => image.image_type_id === 3)) {
        this.setState({
          disabled: false,
          jobRunning: false,
          error: "An ID card image is required",
        });
        passed = false;
      }
    }
    return passed;
  }

  redirectToResult(resp) {
    const jobId = resp.SmileJobID ? resp.SmileJobID : resp?.result?.SmileJobID;
    if (this.state.user.type === "partner") {
      if (jobId) {
        this.props.history.push(
          `/partner/job_results/${getEnvironment()}/${jobId}`,
        );
      } else {
        this.props.history.push("/partner/job_list");
      }
    } else {
      this.props.history.push("/admin/access_logs");
    }
  }

  onSubmitDetails(event) {
    event.preventDefault();
    this.setState({
      disabled: true,
      jobRunning: true,
      error: "",
    });
    if (this.state.jobType === 2 && !this.state.user_id) {
      return this.setState({
        disabled: false,
        jobRunning: false,
        error: `User Reference ID is required`,
      });
    }
    const request = this.createRequestPayload();

    const passed = this.validateRequest(request);
    if (!passed) {
      window.scrollTo(0, 0);
      return;
    }
    postWebApp(request)
      .then((resp) => {
        if (resp.error) {
          // This error is returned when the job has been submitted successfully,
          // but polling for the result has timed out.
          if (resp.error.includes("job list")) {
            return this.redirectToResult(resp);
          }
          this.setState({
            error: resp.error,
            disabled: false,
            jobRunning: false,
            showOutOfCreditsModal: resp.error.includes("out of credits"),
          });
          window.scrollTo(0, 0);
        } else {
          this.redirectToResult(resp);
        }
      })
      .catch((err) => {
        this.setState({
          error: err.message || err.error,
          disabled: false,
          jobRunning: false,
        });
        window.scrollTo(0, 0);
      });
  }

  getRequiredFields() {
    if (this.state.jobType === JobTypes.BUSINESS_VERIFICATION) {
      return this.props.kybTypes[this.state.country][this.state.id_type];
    }
    if (this.state.jobType === JobTypes.AML) {
      return this.props.amlTypes;
    }

    if (this.state.jobType === JobTypes.ADDRESS_VERIFICATION) {
      return this.props.addressVerificationTypes[this.state.country];
    }

    if (this.state.jobType === JobTypes.BIOMETRIC_KYC) {
      return this.props.idTypes.biometric_kyc[this.state.country][
        this.state.id_type
      ];
    }

    if (this.state.jobType === JobTypes.ENHANCED_KYC) {
      return this.props.idTypes.enhanced_kyc[this.state.country][
        this.state.id_type
      ];
    }
    return [];
  }

  isRequired(field) {
    const requiredFields = this.getRequiredFields();
    return requiredFields?.includes(field);
  }

  renderBankOptions() {
    return this.props.bankCodes.map((bank_code) => (
      <option key={bank_code.code} value={bank_code.code}>
        {bank_code.name}
      </option>
    ));
  }

  generateUserId() {
    this.setState({
      user_id: generateRandomID(24),
    });
  }

  renderCountryDropDown() {
    return (
      <div className="web-app__wizard-form-item">
        <label className="label">
          Country
          <span className="asterick">*</span>
          <span
            className="web-app__tooltip"
            data-tooltip="Select the issuing country"
          >
            <img src={InfoIcon} alt="info" />
          </span>
        </label>
        <Select
          options={countries}
          name="country"
          type="text"
          placeholder="Select the issuing country"
          enableSearch
          className="input"
          onChange={(country) => this.handleDocvCountryChange(country)}
          required
        />
      </div>
    );
  }

  renderAMLFields() {
    return (
      <>
        <div className="web-app__wizard-form-item">
          <label className="label">
            Full Name<span className="asterick">*</span>
          </label>
          <input
            className="input"
            name="full_name"
            placeholder="Enter the user's full name"
            type="text"
            onChange={this.handleChange}
          />
        </div>
        <div className="web-app__wizard-form-item">
          <label className="label">
            Nationalities
            <span className="asterick">*</span>
            <span
              className="web-app__tooltip"
              data-tooltip="Can select multiple nationalities"
            >
              <img src={InfoIcon} alt="info" />
            </span>
          </label>
          <Select
            options={countries}
            name="countries"
            placeholder="Select the user's nationalities"
            enableSearch
            className="input"
            isMulti
            onChange={(country) => this.handleCountriesChange(country)}
          />
        </div>
        <div className="web-app__wizard-form-item">
          <label className="label">Year of Birth</label>
          <input
            placeholder="YYYY"
            className="input input--dob"
            name="birth_year"
            type="text"
            onChange={this.handleChange}
          />
        </div>
        <div className="web-app__wizard-form-item">
          <div className="label-container">
            <label className="label">
              Strict Match
              <span
                className="web-app__tooltip"
                data-tooltip="User can specify if strict or more lenient matching criteria is preferred"
              >
                <img src={InfoIcon} alt="info" />
              </span>
            </label>
          </div>
          <Select
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            name="strict_match"
            value={{
              value: this.state.strict_match,
              label: this.state.strict_match === false ? "No" : "Yes",
            }}
            className="input"
            onChange={(option) => this.setState({ strict_match: option.value })}
          />
        </div>
      </>
    );
  }

  renderPersonalInformation() {
    if (this.state.id_type === "BANK_ACCOUNT") {
      return (
        <div className="web-app__wizard-form web-app__wizard-form--id-personal-information">
          <div className="web-app__wizard-form-item">
            <label className="label">
              Account Number<span className="asterick">*</span>
            </label>
            <input
              className="input"
              name="id_number"
              type="text"
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="web-app__wizard-form-item">
            <label className="label">
              Bank Name<span className="asterick">*</span>
            </label>
            <select name="bank_code" type="text" onChange={this.handleChange}>
              <option key="" value="">
                Please select your bank
              </option>
              {this.renderBankOptions()}
            </select>
          </div>
        </div>
      );
    }
    const businessTypeLabel = {
      BASIC_BUSINESS_REGISTRATION: "Company Number",
      BUSINESS_REGISTRATION: "Company Number",
      TAX_INFORMATION: "Company Tax Number",
    };
    const idTypeLabel =
      this.state.jobType == JobTypes.BUSINESS_VERIFICATION
        ? businessTypeLabel[this.state.id_type]
        : "ID Number";
    return (
      <div className="web-app__wizard-form web-app__wizard-form--id-personal-information">
        <div className="web-app__wizard-form-item">
          <label className="label">
            {idTypeLabel || "ID Number"}
            <span className="asterick">*</span>
          </label>
          <input
            className="input"
            name="id_number"
            type="text"
            required
            onChange={this.handleChange}
          />
        </div>
        {this.isRequired("business_type") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Select business type
              <span className="asterick">*</span>
            </label>
            <select
              name="business_type"
              type="text"
              required
              onChange={this.handleChange}
            >
              <option key="" value="">
                Please Business Type
              </option>
              <option value="bn">Business name registration</option>
              <option value="co">Private or public limited companies</option>
              <option value="it">Incorporated trustees</option>
            </select>
          </div>
        )}
        {this.isRequired("postal_address") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Postal address (5 digit code) <span className="asterick">*</span>
            </label>
            <input
              className="input"
              name="postal_address"
              type="text"
              onChange={this.handleChange}
            />
          </div>
        )}
        {this.isRequired("postal_code") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Postal Code<span className="asterick">*</span>
            </label>
            <input
              className="input"
              name="postal_code"
              type="text"
              onChange={this.handleChange}
            />
          </div>
        )}

        {this.isRequired("citizenship") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Citizenship<span className="asterick">*</span>
            </label>

            <select name="citizenship" required onChange={this.handleChange}>
              <option key="" value="">
                Select Citizenship Type
              </option>
              <option value="Kenyan">Kenyan</option>
              <option value="Alien">Alien</option>
            </select>
          </div>
        )}

        {this.isRequired("company") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Company Name<span className="asterick">*</span>
            </label>
            <input
              className="input"
              name="company"
              type="text"
              onChange={this.handleChange}
            />
          </div>
        )}

        {this.isRequired("first_name") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              First Name<span className="asterick">*</span>
            </label>
            <input
              className="input"
              name="first_name"
              type="text"
              onChange={this.handleChange}
            />
          </div>
        )}

        {this.isRequired("middle_name") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Middle Name<span className="asterick">*</span>
            </label>
            <input
              className="input"
              name="middle_name"
              type="text"
              onChange={this.handleChange}
            />
          </div>
        )}

        {this.isRequired("last_name") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Last Name<span className="asterick">*</span>
            </label>
            <input
              className="input"
              name="last_name"
              type="text"
              onChange={this.handleChange}
            />
          </div>
        )}

        {this.isRequired("phone_number") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Phone Number<span className="asterick">*</span>
            </label>
            <input
              className="input"
              name="phone_number"
              type="text"
              onChange={this.handleChange}
            />
          </div>
        )}

        {this.isRequired("dob") && (
          <div className="web-app__wizard-form-item">
            <label className="label">
              Date of Birth<span className="asterick">*</span>
            </label>
            <input
              placeholder="DD"
              className="input input--dob"
              name="date"
              type="text"
              onChange={this.handleChange}
            />
            <select
              name="month"
              type="text"
              className="input input--dob"
              onChange={this.handleChange}
            >
              <option key="MM" value="MM">
                MM
              </option>
              {this.renderMonths()}
            </select>
            <input
              placeholder="YYYY"
              className="input input--dob"
              name="year"
              type="text"
              onChange={this.handleChange}
            />
          </div>
        )}
      </div>
    );
  }

  renderMonths() {
    return [...Array(12).keys()].map((x) => {
      const val = x + 1;
      return (
        <option key={val} value={val}>
          {val}
        </option>
      );
    });
  }

  getProduct(jobType) {
    switch (jobType) {
      case JobTypes.BIOMETRIC_KYC:
        return "biometric_kyc";
      case JobTypes.ENHANCED_KYC:
        return "enhanced_kyc";
      default:
        throw new Error("Unsupported ID type");
    }
  }

  renderIdTypeOptions() {
    let options;
    if (
      this.state.jobType === JobTypes.BIOMETRIC_KYC ||
      this.state.jobType === JobTypes.ENHANCED_KYC
    ) {
      options =
        this.props.idTypes[this.getProduct(this.state.jobType)][
          this.state.country
        ];
    }
    if (this.state.jobType === JobTypes.BUSINESS_VERIFICATION) {
      options = this.props.kybTypes[this.state.country];
    }
    return Object.keys(options)
      .sort()
      .map((idType) => (
        <option key={idType} value={idType}>
          {idType.split("_").join(" ")}
        </option>
      ));
  }

  renderCountryOptions() {
    const countryOptions = {
      NG: {
        name: "Nigeria",
        flag: String.fromCodePoint(0x1f1f3) + String.fromCodePoint(0x1f1ec),
      },
      KE: {
        name: "Kenya",
        flag: String.fromCodePoint(0x1f1f0) + String.fromCodePoint(0x1f1ea),
      },
      GH: {
        name: "Ghana",
        flag: String.fromCodePoint(0x1f1ec) + String.fromCodePoint(0x1f1ed),
      },
      ZA: {
        name: "South Africa",
        flag: String.fromCodePoint(0x1f1ff) + String.fromCodePoint(0x1f1e6),
      },
      UG: {
        name: "Uganda",
        flag: String.fromCodePoint(0x1f1fa) + String.fromCodePoint(0x1f1ec),
      },
      US: {
        name: "USA",
        flag: String.fromCodePoint(0x1f1fa) + String.fromCodePoint(0x1f1f8),
      },
    };
    const supportedCountries =
      this.state.jobType === JobTypes.BUSINESS_VERIFICATION
        ? Object.keys(this.props.kybTypes)
        : Object.keys(this.props.idTypes[this.getProduct(this.state.jobType)]);

    return supportedCountries.map((key) => (
      <option key={key} value={key}>
        {countryOptions[key].flag} {countryOptions[key].name}
      </option>
    ));
  }

  renderIDInformation() {
    return (
      <div>
        <div className="web-app__wizard-form web-app__wizard-form--id-information">
          <div>
            <div className="web-app__wizard-form-item">
              <label className="label">
                Select country
                <span className="asterick">*</span>
              </label>
              <select
                name="country"
                type="text"
                onChange={this.handleCountryChange}
                required
              >
                <option key="" value="">
                  Please select country
                </option>
                {this.renderCountryOptions()}
              </select>
            </div>

            {this.state.country && (
              <div className="web-app__wizard-form-item">
                <label className="label">
                  Choose{" "}
                  {this.state.jobType == JobTypes.BUSINESS_VERIFICATION
                    ? "Service"
                    : "ID"}{" "}
                  Type
                  <span className="asterick">*</span>
                </label>
                <select
                  name="id_type"
                  type="text"
                  value={this.state.id_type}
                  required
                  onChange={this.handleIDTypeChange}
                >
                  <option key="" value="">
                    Please select{" "}
                    {this.state.jobType == JobTypes.BUSINESS_VERIFICATION
                      ? "Service"
                      : "ID"}{" "}
                    Type
                  </option>
                  {this.renderIdTypeOptions()}
                </select>
                <DisplayEnvironment.Consumer>
                  {({ environment }) =>
                    environment &&
                    this.state.jobType == JobTypes.BUSINESS_VERIFICATION && (
                      <ServiceCharge service_price={this.state.service_price} />
                    )
                  }
                </DisplayEnvironment.Consumer>
              </div>
            )}
          </div>

          {this.state.id_type &&
            getProviderImage(this.state.country, this.state.id_type) && (
              <div className="web-app__wizard-form-sample-card-container">
                <div className="web-app__wizard-form-sample-card-name">
                  {" "}
                  {this.state.id_type} Card Sample
                </div>
                <div className="web-app__wizard-form-sample-card">
                  <img
                    className="web-app__wizard-form-sample-card-image"
                    src={getProviderImage(
                      this.state.country,
                      this.state.id_type,
                    )}
                    alt={`${this.state.country}_${this.state.id_type}`}
                  />
                </div>
                <div className="web-app__wizard-form-sample-card-subtext">
                  Note: The{" "}
                  <span className="web-app__wizard-form-sample-card-subtext--red">
                    red circled area
                  </span>{" "}
                  is the ID number
                </div>
              </div>
            )}
        </div>
        {this.state.country &&
          this.state.id_type &&
          this.renderPersonalInformation()}
      </div>
    );
  }

  renderSelfieUpload() {
    return (
      <div className="web-app__wizard-form-item">
        <label className="label">
          Upload Selfie<span className="asterick">*</span>
          <span
            className="web-app__tooltip"
            data-tooltip="1. Max. resolution of 640x480
2. Max. file size of 150kb &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;
3. Supported file types are PNG, JPEG"
          >
            <img src={InfoIcon} alt="info" />
          </span>
        </label>
        <input
          className="input"
          name="selfie_images"
          aria-label="selfie_image"
          multiple
          type="file"
          placeholder="Choose Photo"
          onChange={this.handleImageChange}
          required
          id="2"
        />
      </div>
    );
  }

  renderIDPhotoUpload() {
    return (
      <div className="web-app__wizard-form-item">
        <label className="label">
          Upload front of the ID card<span className="asterick">*</span>
          <span
            className="web-app__tooltip"
            data-tooltip="Max. file size of 10MB. &nbsp; Supported file types are PNG, JPEG"
          >
            <img src={InfoIcon} alt="info" />
          </span>
        </label>
        <input
          className="input"
          name="id_images"
          aria-label="front_id_image"
          type="file"
          placeholder="Choose Photo"
          required
          onChange={this.handleImageChange}
          id="3"
        />
      </div>
    );
  }

  renderBackIDPhotoUpload() {
    return (
      <div className="web-app__wizard-form-item">
        <label className="label">
          Upload back of the ID card
          <span
            className="web-app__tooltip"
            data-tooltip="Max. file size of 10MB. &nbsp; Supported file types are PNG, JPEG"
          >
            <img src={InfoIcon} alt="info" />
          </span>
        </label>
        <input
          className="input"
          name="id_images"
          aria-label="back_id_image"
          type="file"
          placeholder="Choose Photo"
          onChange={this.handleImageChange}
          id="7"
        />
      </div>
    );
  }

  renderUserIdField() {
    const jobType = this.state.jobType;
    // These job types render inputs as they are entered. We do not want to render this
    // input until the other inputs are rendered because otherwise it will bounce around
    // the page.
    if ([1, 5, 7].includes(jobType)) {
      if (!this.state.id_number) {
        return;
      }
    }
    const tooltipCopy =
      jobType === 2
        ? "The User Reference ID for previously registered users can be found on the Job List and Job Results page as User ID"
        : "A user ID is a unique identifier you assign to track this user in Smile ID portal.";
    return (
      <div className="web-app__wizard-form-item" style={{ display: "block" }}>
        <label className="label">
          Enter a user ID {this.state.jobType === 2 ? "" : "(Optional)"}
          {jobType === 2 ? <span className="asterick">*</span> : ""}
          <span className="web-app__tooltip" data-tooltip={tooltipCopy}>
            <img src={InfoIcon} alt="info" />
          </span>
        </label>
        <input
          className="input"
          name="user_id"
          type="text"
          value={this.state.user_id}
          onChange={this.handleChange}
        />
        <p
          onClick={this.generateUserId}
          className="web-app__wizard-form-clickable-text"
        >
          Auto-Generate User ID
        </p>
      </div>
    );
  }

  render() {
    const jobType = parseFloat(this.props.action.job_type);
    return (
      <>
        <form
          onSubmit={this.onSubmitDetails}
          className="web-app__wizard-form web-app__wizard-form--top-level"
        >
          {this.state.error && !this.state.error.includes("out of credits") && (
            <div className="web-app__wizard-error error">
              {this.state.error}
            </div>
          )}
          {jobType === JobTypes.AML && this.renderAMLFields()}
          {jobType === JobTypes.ADDRESS_VERIFICATION && (
            <AddressVerificationFields
              setCountry={(option) => this.setState({ country: option.value })}
              setFormField={this.handleChange}
              country={this.state.country}
              setUtilityProvider={(option) =>
                this.setState({ utility_provider: option.value })
              }
              setAddress={(addr) => this.setState({ address: addr })}
            />
          )}
          <div className="web-app__wizard-form web-app__wizard-form--image-upload">
            {![5, 7, 10, 14].includes(jobType) && this.renderSelfieUpload()}
            {jobType === 6 && this.renderIDPhotoUpload()}
            {jobType === 6 && this.renderBackIDPhotoUpload()}
          </div>
          {[1, 5, 7].includes(jobType) && this.renderIDInformation()}
          {jobType === 6 && this.renderCountryDropDown()}
          {this.renderUserIdField()}
          <div className="web-app__wizard-buttons">
            <button
              className="btn btn-primary--outline"
              type="button"
              onClick={this.onBackButton}
            >
              Go Back
            </button>
            <button
              className="btn btn-primary web-app__wizard-button--submit"
              type="submit"
              disabled={this.state.jobRunning}
            >
              {this.state.jobRunning && <span className="loader-icon" />}
              Submit Details
            </button>
          </div>
        </form>
        {this.state.showOutOfCreditsModal && (
          <OutOfCreditsModal
            isOpen={this.state.showOutOfCreditsModal}
            onClose={this.toggleOutOfCreditsModal}
          />
        )}
      </>
    );
  }
}

function ServiceCharge({ serviceCharge }) {
  const flags = useFlags();
  return (
    flags.showServiceCharge && (
      <div className="web-app__wizard-form-sample-card-subtext">
        <span className="web-app__wizard-form-sample-card-subtext--red">
          <img
            src={InfoIconRed}
            alt="more information"
            className="web-app__wizard-form-sample-card-subtext--red"
          />{" "}
          You will be charged ${serviceCharge} for this check
        </span>
      </div>
    )
  );
}

export default connect(
  ({
    enterprise: {
      partnerInfo: { products },
    },
  }) => ({ products }),
  null,
)(Form);
