import React from "react";
import Select, { SingleValue } from "react-select";

import PlaceAutocomplete from "components/partner/web_app/AutoComplete";
import InfoIcon from "resources/img/icons/info--grey.svg";

export const utilityProviders = [
  {
    label: "AbaElectric",
    value: "F3009D9E-FD0B-4AF9-8151-3BEF3FD903ED",
  },
  {
    label: "AEDC",
    value: "13B5041B-7143-46B1-9A88-F355AD7EA1EC",
  },
  {
    label: "BEDC",
    value: "93CB6363-B065-483C-B235-EA255436B5E0",
  },
  {
    label: "EKEDC",
    value: "8E7485D9-1A67-4205-A49D-691E5B78C20D",
  },
  {
    label: "IBEDC",
    value: "79261A7B-25AA-46A1-8415-B77665BEA67D",
  },
  {
    label: "IkejaElectric",
    value: "8F195A43-78B2-491B-A498-0AAB1088BC3F",
  },
  {
    label: "JED",
    value: "BAB2A62B-F27A-42F3-98C0-8AA1A0ED417B",
  },
  {
    label: "KAEDCO",
    value: "8A821E58-59F0-46B2-97A6-966F3369F3D4",
  },
  {
    label: "KEDCO",
    value: "51AE44EA-AF4D-401D-ACB5-8CEE818720AA",
  },
  {
    label: "PHDisco",
    value: "0a094601-7d0c-4e80-8203-1b4df979ff53",
  },
  {
    label: "PhED",
    value: "A5B4DACC-0391-4887-A76E-2B0584A8C985",
  },
  {
    label: "YEDC",
    value: "D7E42A6F-F74F-4917-AF18-4F979B9F3EFD",
  },
];

type Props = {
  country: string;
  setAddress: (address: string | undefined) => void;
  setCountry: (value: SingleValue<{ value: string }>) => void;
  setFormField: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setUtilityProvider: (value: SingleValue<{ value: string }>) => void;
};

function AddressVerificationFields({
  country,
  setFormField,
  setCountry,
  setUtilityProvider,
  setAddress,
}: Props) {
  const [selectedPlace, setSelectedPlace] =
    React.useState<google.maps.places.PlaceResult | null>(null);

  React.useEffect(() => {
    setAddress(selectedPlace?.formatted_address);
  }, [selectedPlace]);

  const idLabel = country === "NG" ? "Utility Number" : "ID Number";
  return (
    <>
      <div className="web-app__wizard-form-item">
        <label className="label">
          Country
          <span className="asterick">*</span>
          <span className="web-app__tooltip">
            <img src={InfoIcon} alt="info" />
          </span>
        </label>
        <Select
          options={[
            { label: "South Africa", value: "ZA" },
            { label: "Nigeria", value: "NG" },
          ]}
          name="country"
          placeholder="Select the country"
          className="input"
          onChange={setCountry}
        />
      </div>
      <div className="web-app__wizard-form-item">
        <label className="label">
          Address<span className="asterick">*</span>
        </label>
        <PlaceAutocomplete
          setAddress={setAddress}
          className="input"
          onPlaceSelect={setSelectedPlace}
        />
      </div>
      <div className="web-app__wizard-form-item">
        <label className="label">
          {idLabel}
          <span className="asterick">*</span>
        </label>
        <input
          className="input"
          name="id_number"
          type="text"
          onChange={setFormField}
        />
      </div>
      {country === "NG" && (
        <div className="web-app__wizard-form-item">
          <label className="label">
            Utility Provider
            <span className="asterick">*</span>
            <span className="web-app__tooltip">
              <img src={InfoIcon} alt="info" />
            </span>
          </label>
          <Select
            options={utilityProviders}
            name="country"
            placeholder="Select the utility provider"
            className="input"
            onChange={setUtilityProvider}
          />
        </div>
      )}
      <div className="web-app__wizard-form-item">
        <label className="label">
          Full Name<span className="asterick">*</span>
        </label>
        <input
          className="input"
          name="full_name"
          placeholder="full name"
          type="text"
          onChange={setFormField}
        />
      </div>
    </>
  );
}

export default AddressVerificationFields;
