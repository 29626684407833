import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import type { Review } from "types/api/review";

export interface ReviewCreateResponse {
  error: string | null;
  message: string | null;
}

export interface ReviewCreateInput {
  id: number;
  review?: Partial<
    Review & {
      accept: boolean;
      textual_comparisons: Review["links"]["textual_comparisons"];
      rejection_reason: string | null;
    }
  >;
  fraud?: boolean;
  image_unusable?: boolean;
  fraud_type?: string;
}

const createReview = async (input: ReviewCreateInput) => {
  const { data } = await axios.patch<ReviewCreateResponse>(
    `/reviews/${input.id}`,
    input,
  );
  return data;
};

export const useReviewCreate = (
  options?: UseMutationOptions<
    ReviewCreateResponse,
    AxiosError<{ message: string }>,
    ReviewCreateInput
  >,
) =>
  useMutation({
    mutationFn: createReview,
    ...options,
  });
